import React, { useState, useEffect } from 'react';
import { isInViewport } from '../../utils/isInViewport';

function LazyLoadContainer({ showPositionY, children = null, builderBlock }) {
  const [load, setLoad] = useState(null);

  useEffect(() => {
    if (builderBlock?.id) {
      const isAboveFold = isInViewport(
        document.getElementsByClassName(builderBlock.id)[0]
      );
      setLoad(isAboveFold);
    }
  }, [builderBlock?.id]);

  useEffect(() => {
    if (!load) {
      window.addEventListener('scroll', () => {
        setLoad(true);
      });
      window.addEventListener('touchstart', () => {
        setLoad(true);
      });
    }
    return () => {
      window.removeEventListener('scroll', () => {
        setLoad(true);
      });
      window.removeEventListener('touchstart', () => {
        setLoad(true);
      });
    };
  }, [load, setLoad, builderBlock, showPositionY]);

  return load ? children : <div style={{ paddingTop: 100 }} />;
}

export default LazyLoadContainer;
